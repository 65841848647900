import { connect } from 'react-redux';
import { filterCalendar } from '../../redux/reservations/actions';
import Configuration from './Configuration';
import { getAppointmentTypes } from '../../redux/appointmentTypes/actions';

const mapDispatchToProps = {
  filterCalendar,
  getAppointmentTypes,
};

const mapStateToProps = (state) => ({
  configurationDate: state.reservationReducer.configurationDate,
  appointmentTypes: state.appointmentTypesReducer.appointmentTypes,
});


export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
