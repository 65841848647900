import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import * as dateFns from 'date-fns';
import {useParams, withRouter} from 'react-router-dom';
import { toast } from 'react-toastify';
import { DateTime } from "luxon";

import Datepicker from '../common/FormFields/Datepicker';
import CustomScrollbar from '../common/CustomScrollbar';
import Slot from './Slot';
import TimeSlot from './TimeSlotContainer';
import TimeSlotGroup from './TimeSlotGroupContainer';
import TimeScale from './TimeScale';
import Loader from '../common/Loader';
import BodyTimeScale from './BodyTimeScale';
import Resources from './Resources';
import NowTime from './NowTime';
import {
  getStartOfWorkDay,
  getEndOfWorkDay,
  getResourceName,
  usePrevious,
  getTimeslotDateForCurentDay
} from '../../services/helpers';
import {scrollSetting} from '../Dashboard/constants';
import { 
  getDonorBloodType, 
  getDonorStatus,
} from '../../services/helpers';
import redirect from '../../services/redirect';
import routes from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { listenReservations, listenSubscriptions } from '../../services/firestoreServices';

toast.configure();

const Schedule = (props) => {
  const {date} = useParams();
  const { t } = useTranslation();
  const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
  const currentDate = dateFns.startOfDay(!date || !date.match(dateRegex) ? new Date() : new Date(date));
  const offsetDST = 3600000;
  const {
    timeIntervalSize,
    isSettings,
    additionalClassName,
    dailySchedule,
    setDay,
    changeDate,
    calendar,
    calendarIsFetched,
    calendarGet,
    createResource,
    selectResource,
    selectTimeslot,
    hideTimeslotsMenus,
    timeslotMenuIsOpened,
    needRefreshConfiguration,
    currentBloodbank,
    isTimeslotsFetching,
    isDayViewFetching,
    isMonthViewFetching,
    isConfigurationFetching,
    isToastErrorShown,
    isTimeslotErrorToastShown,
    isTwoFactorError,
    launchRefreshing,
    changeConfigurationToRefreshed,
    sendErrorLog
  } = props;

  const scrollableEl = useRef(null);
  const [scrollableArea, setScrollableArea] = useState(scrollableEl.current);
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [scrollEl, setScrollEl] = useState(null);
  const [scrollOffset, setScrollOffset] = useState(0);
  const startOfWorkDay = getStartOfWorkDay(selectedDate);
  const endOfWorkDay = getEndOfWorkDay(selectedDate);

  useEffect(() => {
    setScrollableArea(scrollableEl.current);
  }, []);

  useEffect(() => {
    setDay(currentDate.getTime());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    if(isToastErrorShown || isTimeslotErrorToastShown){
      toast.error('Something went wrong!', {position: toast.POSITION.BOTTOM_RIGHT})
    }
  }, [isTimeslotErrorToastShown, isToastErrorShown, isTwoFactorError])

  useEffect(() => {
    if(needRefreshConfiguration && isSettings && !isConfigurationFetching){
      calendarGet(dateFns.startOfDay(selectedDate).getTime(), currentBloodbank);
    } else if (needRefreshConfiguration && !isConfigurationFetching) {
      changeConfigurationToRefreshed();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needRefreshConfiguration]);

  useEffect(() => {
    if(scrollableArea && scrollEl) {
      const middleAreaPoint = scrollableArea.clientHeight / 2;
      const offset = scrollOffset - middleAreaPoint;
      scrollEl.scrollTop = offset;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollEl, scrollableArea]);

  const prevDate = usePrevious(selectedDate);
  useEffect(() => {
    const disableLoader = prevDate && (prevDate.getTime() === selectedDate.getTime());
    try {
      if(isSettings && !isConfigurationFetching)
        return listenReservations(true, currentDate.getTime(), currentBloodbank, {
          next: () => {
            calendarGet(currentDate.getTime(), currentBloodbank, disableLoader);
          }
        });
    } catch (err) {
      if (err) sendErrorLog(err.message, err.code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  useEffect(() => {
      try {
        return listenSubscriptions({
          next: () => {
            launchRefreshing();
          }
        });
      } catch (err) {
        if (err) sendErrorLog(err.message, err.code);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBloodbank]);

  const scheduleClassName = `schedule ${additionalClassName}`;

  const checkedCalendar = Array.from(calendar);
  const resourcesAmount = dailySchedule.length;
  const configurationAmount = checkedCalendar.length;

  const [scroll, setScroll] = useState(scrollSetting);
  const interval = timeIntervalSize / 60 || 0.5;
  const intervalInMinutes = 60 * interval;

  const getScrollData = (container, direction) => {
    let scrollDirection = 'scrollTop';

    if (direction === 'scrollX') {
      scrollDirection = 'scrollLeft';
    }
    setScroll((prevCount) => {
      const newCount = {...prevCount};
      newCount[scrollDirection] = container[scrollDirection];
      return newCount;
    });
  };

  const getScrollOffset = (data) => {
    setScrollOffset(data);
  };

  document.documentElement.style.setProperty('--resources-amount', !isSettings ? resourcesAmount.toString() : configurationAmount.toString());
  document.documentElement.style.setProperty('--minutes-in-range', intervalInMinutes.toString());

  const handleScroll = () => {
    if (timeslotMenuIsOpened) {
      hideTimeslotsMenus();
    }
  };

  const handleDateChange = (date) => {
    const dateFormat = "yyyy-MM-dd";
    const formattedDate = dateFns.format(date, dateFormat);
    if (!isSettings) {
      redirect(`${routes.DASHBOARD_DAY_ROUTE}/${formattedDate}`);
    } else {
      redirect(`${routes.CONFIGURATION_ROUTE}/${formattedDate}`)
    }
    setSelectedDate(date);
    changeDate(date);
  };

  const renderHeader = () => {
    const dayFormat = "dd";
    const monthFormat = "MMMM";
    const yearFormat = "yyyy";
    const dayOfWeekFormat = "EEEE";

    return (
      <div className="schedule-header">
        <div className="schedule-control">
          <Datepicker onDateChange={handleDateChange} date={currentDate} disabled={isDayViewFetching}/>
        </div>
        <div className="schedule-title">
          <div className="schedule-day">
            {t(`common.day.${dateFns.format(new Date(currentDate), dayOfWeekFormat)}`)}, {dateFns.format(new Date(currentDate), dayFormat)} {t(`common.month.${dateFns.format(new Date(currentDate), monthFormat)}`)}
          </div>
          <div className="schedule-year">{dateFns.format(new Date(selectedDate), yearFormat)}</div>
        </div>
        <div className="schedule-control">
          {isSettings &&
          <button className="btn-secondary has-tip" disabled={!calendarIsFetched || configurationAmount >= 20}
                  onClick={() => {
                    createResource(currentBloodbank, getResourceName(configurationAmount + 1));
                  }}>
            <span className="btn-text">+ {t('configuration.newResource')}</span>
            <span className="btn-tip">{t('configuration.resourceLimit')}</span>
          </button>
          }
        </div>
      </div>
    )
  };

  const renderSlots = (appointments, recourseIndex) => {
    const slots = [];
    let delayStart = startOfWorkDay;
    const sortedAppointments = appointments && appointments.length > 1
      ? appointments.sort((item1, item2) => item1.startDate - item2.startDate)
      : appointments || [];

    for (let i = 0; i < sortedAppointments.length; i++) {
      const {
        appointmentType: {name: type, duration, donorStatus},
        reservation: {user} = {user: {}},
        reservation,
        status,
        donorType,
        startDate,
        endDate,
        timeslotStartDate,
        bloodTiter,
      } = sortedAppointments[i];

      const [firstname, lastname] = (user === null) ? 
          ['######', '######'] : [user?.firstname, user?.lastname];
      const userName = (firstname || lastname) ? [firstname, lastname].join(' ') : '';

      const isTimeslotStartDateDST = DateTime.fromMillis(timeslotStartDate).isInDST;

      let delayEnd = startDate;
      let end = endDate;

      const isCurrentDST = DateTime.fromMillis(delayEnd).isInDST;

      if (isTimeslotStartDateDST === true && isCurrentDST === false) {
        delayEnd = delayEnd + offsetDST;
        end = end + offsetDST;
      }
      if (isTimeslotStartDateDST === false && isCurrentDST === true) {
        delayEnd = delayEnd - offsetDST;
        end = end - offsetDST;
      }

      const currentBloodType = getDonorBloodType(user);
      const currentDonorStatus = getDonorStatus(user);
      const userTitle = (user === null) ?
        '######' : 
        (currentDonorStatus !== '-' && currentBloodType !== '-') ? 
        `${currentDonorStatus} donor, ${currentBloodType}` 
        : '';
      const delay = dateFns.differenceInMinutes(delayEnd, delayStart);
      delayStart = end;

      slots.push(
        <TimeSlot
          scrollableArea={scrollableArea}
          name={userName} 
          key={`timeslot_${recourseIndex}_${i}`}
          currentDetails={sortedAppointments[i]}
          currentAppointment={sortedAppointments[i]}
        >
          <Slot
            type={type}
            size={`duration-${Math.round(duration / 60000)}`}
            delay={`delay-${delay}`}
            name={userTitle}
            status={status}
            bloodTypes={donorType}
            bloodTiter={bloodTiter}
            donor={donorStatus}
            isReservation={!!reservation}
          />
        </TimeSlot>
      );
    }
    return slots;
  };

  const renderCols = dailySchedule
    .map((resource, recourseIndex) => {
      const {appointments} = resource || [];
      return (<div
        className="schedule-col"
        key={`recourse_${recourseIndex}`}
      >
        {renderSlots(appointments, recourseIndex)}
      </div>);
    });



    const splitTimeSlots = (timeslots) => {
      const splitedSlots = [];
      if (timeslots) {
      
        for (let i = 0; i < timeslots.length; i++) {
          const {
            appointments,
          } = timeslots[i];
          const sortedAppointments = appointments.sort((item1, item2) => item1.startDate - item2.startDate);
  
          if (sortedAppointments.length > 1) {
            let prevAppointmentEnd = sortedAppointments[0].endDate;
            let appointmentsInPart = [sortedAppointments[0]];

            for (let j = 1; j < sortedAppointments.length; j++) {
  
              const currAppointmentStart = sortedAppointments[j].startDate;

              if (currAppointmentStart - prevAppointmentEnd > 0) {

                splitedSlots.push({
                  ...timeslots[i],
                  partAppointments: [...appointmentsInPart],
                  partStart: appointmentsInPart[0].startDate,
                  partEnd: appointmentsInPart[appointmentsInPart.length - 1].endDate,
                });
                appointmentsInPart = [sortedAppointments[j]];

              } else {

                appointmentsInPart.push(sortedAppointments[j]);
              }

              prevAppointmentEnd = sortedAppointments[j].endDate;
            }

            if (appointmentsInPart.length) {
              splitedSlots.push({
                ...timeslots[i],
                partAppointments: [...appointmentsInPart],
                partStart: appointmentsInPart[0].startDate,
                partEnd: appointmentsInPart[appointmentsInPart.length - 1].endDate,
              });
            }

          } else {
            splitedSlots.push({
              ...timeslots[i],
              partAppointments: [...appointments],
              partStart: appointments[0].startDate,
              partEnd: appointments[0].endDate,
            });
          }

        }

      }
      return splitedSlots;
    }


  const renderTimeSlots = (timeslots, recourseIndex) => {
    const slots = [];
    let delayStart = startOfWorkDay;

    const splitedTimeSlots = splitTimeSlots(timeslots);

    const sortedTimeslots = splitedTimeSlots && splitedTimeSlots.length > 1
      ? splitedTimeSlots.sort((item1, item2) => item1.partStart - item2.partStart)
      : splitedTimeSlots || [];


    for (let i = 0; i < sortedTimeslots.length; i++) {
      const {
        appointmentType: {name: type, duration, donorStatus},
        donorType,
        bloodTiter,
        partAppointments,
        partStart,
        partEnd,
        startDate
      } = sortedTimeslots[i];
      const sortedAppointments = partAppointments.sort((item1, item2) => item1.startDate - item2.startDate);
      

      // const isTimeslotStartDateDST = DateTime.fromMillis(startDate).isInDST;

      let delayEnd = sortedAppointments[0].startDate;
      let lastAppointmentEnd = sortedAppointments[sortedAppointments.length - 1].endDate;

      // const isCurrentDST = DateTime.fromMillis(delayEnd).isInDST;
      // if(isTimeslotStartDateDST === true && isCurrentDST === false) {
      //   delayEnd = delayEnd + offsetDST;
      //   lastAppointmentEnd = lastAppointmentEnd + offsetDST;
      // }
      // if(isTimeslotStartDateDST === false && isCurrentDST === true) {
      //   delayEnd = delayEnd - offsetDST;
      //   lastAppointmentEnd = lastAppointmentEnd - offsetDST;
      // }

      const delay = dateFns.differenceInMinutes(delayEnd, delayStart);
      delayStart = lastAppointmentEnd;

      const totalDuration = Math.round((partEnd - partStart) / 60000);
      const appointmentDuration = Math.round(duration / 60000);

      const currentStartDate = getTimeslotDateForCurentDay(partStart, currentDate);
      const currentEndDate = getTimeslotDateForCurentDay(partEnd, currentDate);

      const startDowntime = Math.round((sortedAppointments[0].startDate - currentStartDate) / 60000);

      slots.push(
        <TimeSlotGroup
          scrollableArea={scrollableArea}
          type={type}
          appointmentDuration={appointmentDuration}
          bloodTypes={donorType}
          donorStatus={donorStatus}
          bloodTiter={bloodTiter}

          key={`timeslot_${recourseIndex}_${i}_${partStart}`}
          totalDuration={totalDuration}
          timeslotsDelay={delay}
          startDowntime={startDowntime}
          timeslotEndDate={currentEndDate}
          data={sortedTimeslots[i].partAppointments}
          
          onClick={() => selectTimeslot(sortedTimeslots[i])}
        />
      );
    }

    return slots;
  };

  const renderCalendarCols = checkedCalendar
    .map((resource, recourseIndex) => {
      const {timeslots} = resource || [];

      return <div
        className="schedule-col"
        key={`recourse_${recourseIndex}`}
      >
        {renderTimeSlots(timeslots, recourseIndex)}
      </div>
    });

  return (
    <div className={scheduleClassName}>
      <div className="schedule-content">
        {renderHeader()}

        <div className="schedule-subheader">
          <div className="schedule-row">
            <div className="schedule-index schedule-icon">
              <i className="icon-time"/>
            </div>
            <Resources scroll={scroll.scrollLeft}
                       resourcesAmount={!isSettings ? resourcesAmount : configurationAmount}/>
          </div>
        </div>
        <div className="schedule-section">
          <div className="schedule-body">
            <NowTime scroll={scroll.scrollTop} getScrollData={getScrollOffset} />
            <TimeScale scroll={scroll.scrollTop}
                       range={interval}
                       startOfDay={startOfWorkDay}
                       endOfDay={endOfWorkDay}/>
            <div className="schedule-body-inner" ref={scrollableEl}>
            
              <CustomScrollbar
                className="schedule-scrollable"
                options={{
                  suppressScrollX: false,
                }}
                onScrollX={(container) => getScrollData(container, 'scrollX')}
                onScrollY={(container) => getScrollData(container, 'scrollY')}
                onScroll={() => handleScroll()}
                containerRef={ref => {
                  setScrollEl(ref);
                }}
              >
                <BodyTimeScale
                  scroll={scroll.scrollTop}
                  range={interval}
                  startOfDay={startOfWorkDay}
                  endOfDay={endOfWorkDay}
                  resources={dailySchedule.length ? dailySchedule : checkedCalendar}
                  onTimeCellClick={selectResource}
                  isSettings={isSettings}
                />
                {(isSettings && (isTimeslotsFetching || isConfigurationFetching)) || (!isSettings && (isMonthViewFetching || isDayViewFetching)) ? <Loader className="is-fixed" /> :
                <div className="schedule-slots">
                  {isSettings && renderCalendarCols}
                  {!isSettings && renderCols}
                </div>}
              </CustomScrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>
              
  );
};


Schedule.propTypes = {
  dailySchedule: PropTypes.array,
  setMonth: PropTypes.func,
};

Schedule.defaultProps = {
  dailySchedule: [],
};

export default withRouter(Schedule);
