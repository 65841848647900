import * as dateFns from 'date-fns';
import {
  timestampToWorkingTime,
} from '../../../services/helpers';
import { WEEKDAYS } from './constants';
import { DateTime } from "luxon";

export const changeTimestampByDTS = (timestamp, timeslotStart, reverse=true) => {
  const offsetDST = 3600000;

  if (!timeslotStart) {
    return timestamp;
  }

  const isTimeslotStartDST = DateTime.fromMillis(timeslotStart).isInDST;
  const isCurrentDST = DateTime.fromMillis(timestamp).isInDST;

  if(isTimeslotStartDST === true && isCurrentDST === false) {
    return reverse ? timestamp - offsetDST : timestamp + offsetDST;
  }
  if(isTimeslotStartDST === false && isCurrentDST === true) {
    return reverse ? timestamp + offsetDST : timestamp - offsetDST;
  }
  return timestamp;
}

export const isSelectedTab = (selectedTab, tab) => selectedTab === tab;

export const prepareDatesToMapping = (data) => {
  const dates = [...data];
  return dates.map((item, index) => {
    return {
      id: String(index + 1),
      label: dateFns.format(item, "dd MMMM yyyy"),
      value: dateFns.format(item, "dd_MM_yy"),
      sentValue: item,
    }
  });
}

export const prepareHoursToMapping = (data) => {
  const hours = [...data];
  return hours.map((item, index) => {
    const timeFrom = timestampToWorkingTime(item.from);
    const timeTo = timestampToWorkingTime(item.to);
    const checkedDays = WEEKDAYS.filter((el) => item.byweekday.includes(Number(el.value)));
    const labelDays = checkedDays.map((item) => item.label).join(', ');
    const valueDays = checkedDays.map((item) => item.label).join('_');
    return {
      id: String(index + 1),
      label: `From ${timeFrom.label} to ${timeTo.label} on ${labelDays}`,
      value: `${timeFrom.value}_${timeTo.value}_${valueDays}`,
      sentValue: item
    }
  });
}

export const prepareHoursOnDateToMapping = (data) => {
  const hoursAndDates = [...data];
  return hoursAndDates.map((item, index) => {
    const timeFrom = timestampToWorkingTime(item.from);
    const timeTo = timestampToWorkingTime(item.to);
    const labelDate = dateFns.format(item.date, "dd MMMM yyyy");
    const valueDate = dateFns.format(item.date, "dd_MM_yy");
    return {
      id: String(index + 1),
      label: `From ${timeFrom.label} to ${timeTo.label} on ${labelDate}`,
      value: `${timeFrom.value}_${timeTo.value}_${valueDate}`,
      sentValue: item
    }
  });
}

export const prepareToSending = (data) => {
  const newData = [...data];
  return newData.map((item) => {
    return item.sentValue
  });
}

export const makeTextRules = (exceptionDates, exceptionRules, exceptionHoursOnDates, timeslotStart) => {
  const dates = [...exceptionDates];
  const rules = [...exceptionRules];
  const hoursOnDates = [...exceptionHoursOnDates];
  
  return [
    ...dates.map((item) => {
      return dateFns.format(item, "dd MMMM yyyy")
    }), 
    ...rules.map((item) => {
      const timeFrom = timestampToWorkingTime(changeTimestampByDTS(item.from, timeslotStart, false));
      const timeTo = timestampToWorkingTime(changeTimestampByDTS(item.to, timeslotStart, false));
      const checkedDays = WEEKDAYS.filter((el) => item.byweekday.includes(Number(el.value)));
      const labelDays = checkedDays.map((item) => item.label).join(', ');
      return `From ${timeFrom.label} to ${timeTo.label} on ${labelDays}`
    }),
    ...hoursOnDates.map((item) => {
      const timeFrom = timestampToWorkingTime(changeTimestampByDTS(item.from, timeslotStart, false));
      const timeTo = timestampToWorkingTime(changeTimestampByDTS(item.to, timeslotStart, false));
      const labelDate = dateFns.format(item.date, "dd MMMM yyyy");
      return `From ${timeFrom.label} to ${timeTo.label} on ${labelDate}`
    })
  ]
}