import * as dateFns from 'date-fns';
import { enUS, nb } from 'date-fns/locale';
import i18n from '../../i18n';
import {
    DONORS_HEADINGS
  } from '../../constants/donors';
import { 
  getDonorBloodType, 
  getDonorStatus,
} from '../../services/helpers';

export const prepareRowData = (currentUser) => {
    const locale = (i18n.language === 'no') ? nb : enUS;
    if (currentUser) return {
        [DONORS_HEADINGS.ID_NUMBER]: currentUser.id || '-',
        [DONORS_HEADINGS.LAST_DRAW]: currentUser.lastDonation ? 
            dateFns.format(new Date(currentUser.lastDonation), 'd MMMM yyyy', {locale}) : 
            '-',
        [DONORS_HEADINGS.BLOOD_TYPE]: getDonorBloodType(currentUser),
        [DONORS_HEADINGS.TITER]: currentUser.titer ? i18n.t(`configuration.settings.titer.${currentUser.titer}`) : '-',
        [DONORS_HEADINGS.DONOR_STATUS]: getDonorStatus(currentUser),
        [DONORS_HEADINGS.DONOR_TYPE]: currentUser.type ? i18n.t(`common.donorType.${currentUser.type}`) : '-',
        [DONORS_HEADINGS.QUARANTINE_DUE_DATE]: currentUser.quarantine ? 
            dateFns.format(new Date(currentUser.quarantine), 'd MMMM yyyy', {locale}) : 
            '-',
        [DONORS_HEADINGS.APP_USER]: currentUser.fcmDeviceId ? i18n.t('common.appUserStatus.yes') : i18n.t('common.appUserStatus.no'),
        [DONORS_HEADINGS.SMS]: currentUser.sms === true ? i18n.t('common.smsStatus.true') : currentUser.sms === false ? i18n.t('common.smsStatus.false') : '-',
        [DONORS_HEADINGS.NEXT_APPOINTMENT]: currentUser.nextAppointment && 
                                                (currentUser.status !== 'new_registration') && 
                                                    currentUser.nextAppointment !== '' ?
            `${dateFns.format(new Date(currentUser.nextAppointment.startDate), 'd MMMM yyyy', {locale})}\n${dateFns.format(new Date(currentUser.nextAppointment.startDate), 'HH:mm')}-${dateFns.format(new Date(currentUser.nextAppointment.endDate), 'HH:mm')}` 
            : '-'
    }
};

export const prepareRowDataForSorting = (currentUser) => {
  if (currentUser) return {
      [DONORS_HEADINGS.ID_NUMBER]: currentUser.id || '-',
      [DONORS_HEADINGS.LAST_DRAW]: currentUser.lastDonation || 0,
      [DONORS_HEADINGS.BLOOD_TYPE]: getDonorBloodType(currentUser),
      [DONORS_HEADINGS.TITER]: currentUser.titer ? i18n.t(`configuration.settings.titer.${currentUser.titer}`) : '-',
      [DONORS_HEADINGS.DONOR_STATUS]: getDonorStatus(currentUser),
      [DONORS_HEADINGS.DONOR_TYPE]: currentUser.type ? i18n.t(`common.donorType.${currentUser.type}`) : '-',
      [DONORS_HEADINGS.QUARANTINE_DUE_DATE]: currentUser.quarantine || 0,
      [DONORS_HEADINGS.APP_USER]: currentUser.fcmDeviceId ? i18n.t('common.appUserStatus.yes') : i18n.t('common.appUserStatus.no'),
      [DONORS_HEADINGS.SMS]: currentUser.sms === true ? i18n.t('common.smsStatus.true') : currentUser.sms === false ? i18n.t('common.smsStatus.false') : '-',
      [DONORS_HEADINGS.NEXT_APPOINTMENT]: currentUser.nextAppointment && 
                                            (currentUser.status !== 'new_registration') && 
                                              currentUser.nextAppointment !== '' ? currentUser.nextAppointment.startDate : 0,
  }
};

export const sortTableData = (
    tableData,
    sortKey,
    clicks,
  ) => {
    let newTableData = [...tableData];

    if (tableData.length > 1) {
      let value = 0;

      if(typeof tableData[0].sortable[sortKey] === 'string') {
        newTableData = newTableData.sort(
          (a, b) => {
            if (a.sortable[sortKey] === "-") {
                return 1;
            }
            if (a.sortable[sortKey] > b.sortable[sortKey]) {
              value = clicks % 2 ? -1 : 1;
            }
            if (a.sortable[sortKey] < b.sortable[sortKey]) {
              value = clicks % 2 ? 1 : -1;
            }
            return value;
          },
        );
      }

      if(typeof tableData[0].sortable[sortKey] === 'number') {
        newTableData = newTableData.sort(
          (a, b) => {
            if (a.sortable[sortKey] === 0) {
              return 1;
            }
            if (+a.sortable[sortKey] > +b.sortable[sortKey]) {
              value = clicks % 2 ? -1 : 1;
            }
            if (+a.sortable[sortKey] < +b.sortable[sortKey]) {
              value = clicks % 2 ? 1 : -1;
            }
            return value;
          },
        );
      }      
    }
    return [...newTableData];
  };
  