import { createAction } from 'redux-actions';
import * as timeslotActions from './actionTypes';
import {
  calcAppointmentsInTimeslot,
  checkReservationsExists,
  createTimeslot,
  deleteTimeslot,
  editTimeslot
} from '../../services/fetch';
import {
  timeslotBloodTypeValidationMessage,
  timeslotEndTimeValidationMessage,
  timeslotStartTimeValidationMessage,
  timeslotTimeDifferenceValidationMessage,
  timeslotTimeDurationValidationMessage
} from "../../services/validation";
import { calendarGet } from "../reservations/actions";
import * as dateFns from 'date-fns';

export const createTimeslotSuccess = createAction(timeslotActions.CREATE_TIMESLOT_SUCCESS);
export const createTimeslotFailure = createAction(timeslotActions.CREATE_TIMESLOT_FAILURE);
export const toggleTimeslotSettings = createAction(timeslotActions.TOGGLE_TIMESLOT_SETTINGS);
export const setSelectedResource = createAction(timeslotActions.SET_SELECTED_RESOURCE);
export const setSelectedTimeslot = createAction(timeslotActions.SET_SELECTED_TIMESLOT);
export const clearValidationErrors = createAction(timeslotActions.CLEAR_VALIDATION_ERRORS);
export const hideMenus = createAction(timeslotActions.HIDE_MENUS);
export const setMenuIsOpen = createAction(timeslotActions.SET_MENU_IS_OPENED);
export const setRule = createAction(timeslotActions.SET_RECURRENCE_RULE);
export const deleteRule = createAction(timeslotActions.DELETE_RECURRENCE_RULE);
export const deleteTimeslotSuccess = createAction(timeslotActions.DELETE_TIMESLOT_SUCCESS);
export const deleteTimeslotFailure = createAction(timeslotActions.DELETE_TIMESLOT_FAILURE);
export const toggleDeleteModal = createAction(timeslotActions.TOGGLE_DELETE_TIMESLOT_MODAL);
export const setReservationsExists = createAction(timeslotActions.SET_RESERVATIONS_EXISTS);
export const fetchTimeslots = createAction(timeslotActions.FETCH_TIMESLOTS);
export const afterSelect = createAction(timeslotActions.AFTER_SELECT);
export const setNewExceptionRule = createAction(timeslotActions.SET_EXCEPTION_RULES);
export const setNewExceptionDate = createAction(timeslotActions.SET_REXCEPTION_DATES);
export const setNewExceptionHoursOnDate = createAction(timeslotActions.SET_REXCEPTION_HOURS_ON_DATES);
export const deleteExceptions = createAction(timeslotActions.DELETE_ALL_EXCEPTIONS);
export const toggleAppointmentModal = createAction(timeslotActions.TOGGLE_DELETE_APPOINTMENT_MODAL);
export const toggleBookingModal = createAction(timeslotActions.TOGGLE_BOOK_APPOINTMENT_MODAL);
export const toggleBookingCancelModal = createAction(timeslotActions.TOGGLE_BOOKING_CANCEL_MODAL);
export const setAppointmentsAmount = createAction(timeslotActions.SET_APPOINTMENTS_AMOUNT);


export const setTimeslotRequest = (data, duration) => async (dispatch, getState) => {
  await dispatch(clearValidationErrors());
  dispatch(fetchTimeslots())
  const startTimeError = timeslotStartTimeValidationMessage(data);
  const differenceTimeError = timeslotTimeDifferenceValidationMessage(data);
  const durationTimeError = timeslotTimeDurationValidationMessage(data, duration);
  const endTimeError = timeslotEndTimeValidationMessage(data, duration);
  const bloodTypeError = timeslotBloodTypeValidationMessage(data);
  const { currentBloodbank } = getState().authReducer;
  if (startTimeError || endTimeError || bloodTypeError || differenceTimeError || durationTimeError) {
    dispatch(createTimeslotFailure({startTimeError, endTimeError, bloodTypeError, differenceTimeError, durationTimeError}));
    return;
  }
  let timeslotPromise;
  if (data.timeslotId) {
    timeslotPromise = editTimeslot(data, currentBloodbank)
  } else {
    timeslotPromise = createTimeslot(data, currentBloodbank)
  }
  const { settingsModalIsOpen } = getState().timeslotsReducer;
  timeslotPromise
    .then(res => {
      dispatch(createTimeslotSuccess(res));
      dispatch(deleteRule());
      dispatch(deleteExceptions());
      if (settingsModalIsOpen) {
        dispatch(toggleSettings());
      }
      const { currentDate } = getState().reservationReducer;
      dispatch(calendarGet(currentDate.getTime(), currentBloodbank))
    })
  .catch(err => {
    dispatch(createTimeslotFailure({backendError: err.message}))
  })
};

export const toggleSettings = () => (dispatch, getState) => {
  const { settingsModalIsOpen } = getState().timeslotsReducer;
  if (settingsModalIsOpen) {
    dispatch(deleteRule());
    dispatch(deleteExceptions());
  }
  dispatch(toggleTimeslotSettings());
};

export const toggleDeleteTimeslotModal = () => dispatch => {
  dispatch(toggleDeleteModal());
};

export const selectResource = resourceId => dispatch => {
  dispatch(toggleSettings());
  dispatch(setSelectedResource(resourceId));
};

export const selectTimeslot = timeslotData => dispatch => {
  dispatch(setSelectedTimeslot(timeslotData));
};

export const hideTimeslotsMenus = () => dispatch => {
  dispatch(hideMenus());
};

export const setTimeslotMenuIsOpen = () => (dispatch, getState) => {
  dispatch(setMenuIsOpen());
  const { selectedTimeslot } = getState().timeslotsReducer;
  const { currentBloodbank } = getState().authReducer;
  checkReservationsExists(selectedTimeslot.timeslotId, currentBloodbank)
  .then(res => {
    dispatch(setReservationsExists(res));
  })
};

export const setRecurrenceRule = rule => dispatch => {
  dispatch(setRule(rule));
};

export const deleteRecurrenceRule = () => dispatch => {
  dispatch(deleteRule());
};

export const deleteTimeslotRequest = (timeslot) => (dispatch, getState) => {
  const { startDate, ruleId } = timeslot;
  const { currentBloodbank } = getState().authReducer;
  deleteTimeslot({ruleId}, currentBloodbank)
    .then(res => {
      dispatch(deleteTimeslotSuccess(res, currentBloodbank));
      dispatch(deleteRule());
      dispatch(deleteExceptions());
      dispatch(calendarGet(dateFns.startOfDay(startDate).getTime(), currentBloodbank));
    })
    .catch(err => dispatch(deleteTimeslotFailure(err)));
};

export const setExceptionRules = rules => dispatch => {
  dispatch(setNewExceptionRule(rules));
};

export const setExceptionDates = dates => dispatch => {
  dispatch(setNewExceptionDate(dates));
};

export const setExceptionHoursOnDates = rules => dispatch => {
  dispatch(setNewExceptionHoursOnDate(rules));
};

export const deleteAllExceptions = () => dispatch => {
  dispatch(deleteExceptions());
};

export const toggleDeleteAppointmentModal = () => dispatch => {
  dispatch(toggleAppointmentModal());
};

export const toggleBookAppointmentModal = () => dispatch => {
  dispatch(toggleBookingModal());
};

export const getAppointmentsAmount = () => (dispatch, getState) => {
  const { selectedTimeslot } = getState().timeslotsReducer;
  const { currentBloodbank } = getState().authReducer;
  calcAppointmentsInTimeslot(selectedTimeslot.timeslotId, currentBloodbank)
  .then(res => {
    dispatch(setAppointmentsAmount(res));
  })
};