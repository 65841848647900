import { createAction } from 'redux-actions';
import {
  GET_RESERVATIONS_FOR_DAY,
  GET_RESERVATIONS_FOR_PREV_DAYS,
  REQUEST_FAIL,
  CHANGE_CHOSEN_DATE,
  UPDATE_STATUS_CONFIRMED,
  UPDATE_STATUS_DENIED,
  GET_CONFIRMED_RESERVATIONS,
  MARK_DID_ATTEND,
  MARK_DID_NOT_ATTEND,
  GET_TIMETABLE_SUCCESS,
  GET_SCHEDULE_SUCCESS,
  RESET_SCHEDULE_SUCCESS,
  CHANGE_CURRENT_DAY,
  CHANGE_CURRENT_MONTH,
  GET_CALENDAR_SUCCESS,
  FILTER_CALENDAR_SUCCESS,
  CHANGE_CONFIGURATION_DATE,
  CREATE_RESOURCE_SUCCESS,
  DELETE_RESOURCE_SUCCESS,
  EDIT_RESOURCE_SUCCESS,
  EDIT_NOT_CHECKED,
  GET_NEW_USERS_REGISTERED,
  UPDATE_DONOR,
  REFRESH_CONFIGURATION,
  SET_APP_TYPE_FILTER,
  SET_DONOR_STATUS_FILTER,
  SET_TITER_FILTER,
  SET_BLOOD_TYPE_FILTER,
  FETCHING_DAY,
  FETCHING_MONTH,
  FETCHING_SIDEBAR,
  FETCHING_CONFIGURATION,
  SET_REFRESHED,
  CHANGE_RESERVATION_SUCCESS,
  SET_NOT_REFRESHED,
  SET_CONFIGURATION_REFRESHED,
  TOGGLE_RESOURCE_MENU,
  SET_RESOURCE,
  SET_IS_RESOURCE_EMPTY,
  TOGGLE_DELETE_RESOURCE_MODAL,
  TOGGLE_EDIT_RESOURCE_MODAL,
} from './actionTypes';
import { 
  getReservationsForDay,
  createReservation,
  cancelReservation,
  updateStatus, 
  getTimetable, 
  get, 
  getCalendar, 
  resourceCreate,
  resourceEdit,
  resourceDelete,
  getNewUsersRegistered, 
  editEncryptedUser
} from '../../services/fetch';
import { getValidIdToken } from "../auth/actions";
import { sendErrorLog } from "../logging/actions";
import {batch} from "react-redux";
import {DateTime} from "luxon";

export const requestFail = createAction(REQUEST_FAIL);
export const reservationsForDay = createAction(GET_RESERVATIONS_FOR_DAY);
export const reservationsForPrevDays = createAction(GET_RESERVATIONS_FOR_PREV_DAYS);
export const changeCurrentDate = createAction(CHANGE_CHOSEN_DATE);
export const updateStatusConfirmed = createAction(UPDATE_STATUS_CONFIRMED);
export const updateStatusDenied = createAction(UPDATE_STATUS_DENIED);
export const confirmedForDay = createAction(GET_CONFIRMED_RESERVATIONS);
export const didAttend = createAction(MARK_DID_ATTEND);
export const didNotAttend = createAction(MARK_DID_NOT_ATTEND);
export const getTimetableSuccess = createAction(GET_TIMETABLE_SUCCESS);
export const getScheduleSuccess = createAction(GET_SCHEDULE_SUCCESS);
export const resetScheduleSuccess = createAction(RESET_SCHEDULE_SUCCESS);
export const changeDay = createAction(CHANGE_CURRENT_DAY);
export const changeMonth = createAction(CHANGE_CURRENT_MONTH);
export const getCalendarSuccess = createAction(GET_CALENDAR_SUCCESS);
export const filterCalendarSuccess = createAction(FILTER_CALENDAR_SUCCESS);
export const changeConfigurationDateSuccess = createAction(CHANGE_CONFIGURATION_DATE);
export const createResourceSuccess = createAction(CREATE_RESOURCE_SUCCESS);
export const deleteResourceSuccess = createAction(DELETE_RESOURCE_SUCCESS);
export const editResourceSuccess = createAction(EDIT_RESOURCE_SUCCESS);
export const editNotChecked = createAction(EDIT_NOT_CHECKED);
export const newUsersRegistered = createAction(GET_NEW_USERS_REGISTERED);
export const editDonorSuccess = createAction(UPDATE_DONOR);
export const setCurrentAppTypeFilter = createAction(SET_APP_TYPE_FILTER);
export const setCurrentBloodTypeFilter = createAction(SET_BLOOD_TYPE_FILTER);
export const setCurrentDonorStatusFilter = createAction(SET_DONOR_STATUS_FILTER);
export const setCurrentTiterFilter = createAction(SET_TITER_FILTER);
export const refreshConfiguration = createAction(REFRESH_CONFIGURATION);
export const fetchingSidebar = createAction(FETCHING_SIDEBAR);
export const fetchingDay = createAction(FETCHING_DAY);
export const fetchingMonth = createAction(FETCHING_MONTH);
export const fetchingConfiguration = createAction(FETCHING_CONFIGURATION);
export const setRefreshed = createAction(SET_REFRESHED);
export const changeReservationSuccess = createAction(CHANGE_RESERVATION_SUCCESS);
export const setNotRefreshed = createAction(SET_NOT_REFRESHED);
export const setConfigurationRefreshed = createAction(SET_CONFIGURATION_REFRESHED);
export const setResourceMenuIsOpen = createAction(TOGGLE_RESOURCE_MENU);
export const setSelectedResource = createAction(SET_RESOURCE);
export const setIsResourceEmpty = createAction(SET_IS_RESOURCE_EMPTY);
export const toggleDeleteResourceModal = createAction(TOGGLE_DELETE_RESOURCE_MODAL);
export const toggleEditResourceModal = createAction(TOGGLE_EDIT_RESOURCE_MODAL);

export const bookAppointment = (startDate, timeslotId, userId, bloodbankId) => (dispatch) => {
  dispatch(fetchingDay());
  dispatch(fetchingSidebar());

  createReservation(`/reservations/bloodbank/${bloodbankId}`, startDate, timeslotId, userId)
  .then(() => {
    return dispatch(changeReservationSuccess());
  })
  .then(() => {
    return dispatch(refreshConfiguration());
  })
  .catch(err => {
    return dispatch(requestFail(err));
  });
};

export const cancelAppointmentBooking = (bloodbankId, reservationId, userId, reservationTimestamp, bloodbankName) => (dispatch) => {
  dispatch(fetchingDay());
  dispatch(fetchingSidebar());

  cancelReservation(`/mobile/cancelReservation/bloodbank/${bloodbankId}`, reservationId, userId, reservationTimestamp, bloodbankName)
  .then(() => {
    return dispatch(changeReservationSuccess());
  })
  .then(() => {
    return dispatch(refreshConfiguration());
  })
  .catch(err => {
    return dispatch(requestFail(err));
  });
};

export const getDailyReservations = (date, appointmentType, bloodType, donorStatus, titer, bloodbankId, isLoaderNotNeeded) => (dispatch) => {
  if(!isLoaderNotNeeded)
  dispatch(fetchingSidebar());

  getReservationsForDay(`/reservations/getByDay/bloodbank/${bloodbankId}/${date}/confirmed/${appointmentType}/${bloodType}/${donorStatus}/${titer}`)
    .then((res) => dispatch(reservationsForDay(res)))
    .catch((err) => dispatch(requestFail(err)));
};

export const getPrevReservations = (date, appointmentType, bloodType, donorStatus, titer, bloodbankId, isLoaderNotNeeded) => (dispatch) => {
  if(!isLoaderNotNeeded)
  dispatch(fetchingSidebar());

  get(`/reservations/get/bloodbank/${bloodbankId}/${date}/confirmed/${appointmentType}/${bloodType}/${donorStatus}/${titer}`)
    .then((res) => dispatch(reservationsForPrevDays(res)))
    .catch((err) => dispatch(requestFail(err)));
};

export const getNewUsers = (bloodbankId, isLoaderNotNeeded) => (dispatch) => {
  if(!isLoaderNotNeeded)
  dispatch(fetchingSidebar());

  getNewUsersRegistered(`/users/status/new_registration/bloodbank/${bloodbankId}`)
  .then((res) => dispatch(newUsersRegistered(res)))
  .catch((err) => dispatch(requestFail(err)));
}

export const updateDonor = (user) => async (dispatch) => {
  dispatch(fetchingDay());
  dispatch(fetchingSidebar());

  const token = await dispatch(getValidIdToken());
  editEncryptedUser(user.id, user, token)
  .then(() => dispatch(editDonorSuccess()))
  .catch(err => {
    dispatch(sendErrorLog(err.message, err.status, 'ekm', err.url));
    dispatch(requestFail(err));
  });
}

export const changeDate = (date) => {
  return changeCurrentDate(date);
}

export const markDidAttend = (status, details, donorStatus, bloodbankId) => (dispatch) => {
  dispatch(fetchingDay());
  dispatch(fetchingSidebar());

  updateStatus(`/reservations/bloodbank/${bloodbankId}/${details.reservationId}`, status,  donorStatus)
  .then(() =>  dispatch(didAttend(details)))
  .catch((err => dispatch(requestFail(err))));
}

export const markDidNotAttend = (status, details, donorStatus, bloodbankId) => (dispatch) => {
  dispatch(fetchingDay());
  dispatch(fetchingSidebar());

  updateStatus(`/reservations/bloodbank/${bloodbankId}/${details.reservationId}`, status,  donorStatus)
  .then(() => dispatch(didNotAttend(details)))
  .catch((err => dispatch(requestFail(err))));
};

export const getTimetableRequest = (monthStart, bloodbankId, appType, bloodType, donorStatus, titer) => dispatch => {
  dispatch(fetchingMonth())

  if(monthStart){
  getTimetable(`/appointments/getTimeTable/bloodbank/${bloodbankId}/${monthStart}/${appType}/${bloodType}/${donorStatus}/${titer}`)
    .then(res => dispatch(getTimetableSuccess(res)))
    .catch(err => dispatch(requestFail(err)));
  }
};

export const resetSchedule = (date, bloodbankId) => async (dispatch) => {
  dispatch(fetchingDay());

  if(date){
    await get(`/reservations/schedule/bloodbank/${bloodbankId}/${date}/all/all/all/all`)
      .then(res => {
        batch(() => {
          dispatch(changeDate(DateTime.fromMillis(date).toJSDate()));
          dispatch(setDay(date));
          dispatch(resetScheduleSuccess(res))
        })
      })

      .catch(err => {
        console.log(err);
        dispatch(requestFail(err))
      });
  }
};

export const changeFilter = (dailyView, date, appType, bloodType, donorStatus, titer, bloodbankId, isLoaderNotNeeded) => dispatch => {
  if(!isLoaderNotNeeded){
    if(dailyView){
      dispatch(fetchingDay());
    } else {
      dispatch(fetchingMonth());
    };
  }

  if (dailyView) {

    get(`/reservations/schedule/bloodbank/${bloodbankId}/${date}/${appType}/${bloodType}/${donorStatus}/${titer}`)
      .then(res => dispatch(getScheduleSuccess(res)))
      .catch(err => dispatch(requestFail(err)));
  } else {

    getTimetable(`/appointments/getTimeTable/bloodbank/${bloodbankId}/${date}/${appType}/${bloodType}/${donorStatus}/${titer}`)
    .then(res => dispatch(getTimetableSuccess(res)))
    .catch(err => dispatch(requestFail(err)));

  }
};

export const setDay = (date) => {
  return changeDay(date);
}

export const setMonth = (date) => dispatch => {
  dispatch(changeMonth(date));
}

export const calendarGet = (date, bloodbankId, disableLoader) => dispatch => {
  if (!disableLoader) {
    dispatch(fetchingConfiguration());
  }

  getCalendar(`/timeslots/configuration/bloodbank/${bloodbankId}/${date}/all`)
    .then(res => dispatch(getCalendarSuccess(res)))
    .catch(err => dispatch(requestFail(err)))
}

export const filterCalendar = (date, donorType, bloodbankId) => dispatch => {
  getCalendar(`/timeslots/configuration/bloodbank/${bloodbankId}/${date}/${donorType}`)
    .then(res => dispatch(filterCalendarSuccess(res)))
    .catch(err => dispatch(requestFail(err)))
}

export const changeConfigurationDate = (date) => dispatch => {
  dispatch(changeConfigurationDateSuccess(date));
}

export const createResource = (bloodbankId, resourceName) => dispatch => {
  dispatch(fetchingConfiguration());

  resourceCreate(`/resources/bloodbank/${bloodbankId}`, {resourceName})
    .then(res => dispatch(createResourceSuccess(res)))
    .then(() => {
      return dispatch(refreshConfiguration())
    })
    .catch(err => dispatch(requestFail(err)));
}

export const checkIsResourceEmpty = (bloodbankId, resourceId) => dispatch => {
  dispatch(setIsResourceEmpty(null));

  get(`/resources/check/bloodbank/${bloodbankId}/${resourceId}`)
    .then(res => dispatch(setIsResourceEmpty(res)))
    .catch(err => dispatch(requestFail(err)));
}

export const deleteResource = (bloodbankId, resourceId) => dispatch => {
  dispatch(fetchingConfiguration());

  resourceDelete(`/resources/bloodbank/${bloodbankId}/${resourceId}`)
    .then(res => {
      return dispatch(deleteResourceSuccess(res))
    })
    .then(() => {
      return dispatch(refreshConfiguration())
    })
    .catch(err => {
      return dispatch(requestFail(err))
    });
}

export const editResource = (bloodbankId, resourceId, resourceName) => dispatch => {
  dispatch(fetchingConfiguration());

  resourceEdit(`/resources/edit/bloodbank/${bloodbankId}`, { resourceId, resourceName })
    .then(res => dispatch(editResourceSuccess(res)))
    .then(() => {
      return dispatch(refreshConfiguration())
    })
    .catch(err => dispatch(requestFail(err)));
}

export const setAppTypeFilter = currentFilter => dispatch => {
  dispatch(setCurrentAppTypeFilter(currentFilter));
};

export const setBloodTypeFilter = currentFilter => dispatch => {
  dispatch(setCurrentBloodTypeFilter(currentFilter));
};

export const setDonorStatusFilter = currentFilter => dispatch => {
  dispatch(setCurrentDonorStatusFilter(currentFilter));
};

export const setTiterFilter = currentFilter => dispatch => {
  dispatch(setCurrentTiterFilter(currentFilter));
};

export const changeToRefreshed = () => dispatch => {
  dispatch(setRefreshed());
};

export const launchRefreshing = () => dispatch => {
  dispatch(refreshConfiguration());
  dispatch(setNotRefreshed());
};

export const changeConfigurationToRefreshed = () => dispatch => {
  dispatch(setConfigurationRefreshed());
};