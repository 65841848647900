import React, {useState, useEffect} from 'react';
import Header from '../common/Header';
import DonorDetails from './Modals/DonorDetailsContainer';
import DonorSettings from './Modals/DonorSettingsContainer';
// import Search from '../common/FormFields/Search';
import CustomSelect from '../common/FormFields/Select';
import Sidebar from './SidebarContainer';
import {DONOR_STATUS, DONOR_TYPE, BLOOD_TITER} from './constants';
import {useParams, withRouter} from 'react-router-dom';
import routes from '../../constants/routes';
import { listenReservations, listenNewRegUsers } from "../../services/firestoreServices";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { usePrevious } from '../../services/helpers';

const Dashboard = (props) => {
  const {
    children,
    changeFilter,
    currentDay,
    currentMonth,
    getDailyReservations,
    getPrevReservations,
    currentDate,
    location,
    getNewUsers,
    needRefresh,
    setAppTypeFilter,
    setBloodTypeFilter,
    setDonorStatusFilter,
    setTiterFilter,
    appTypeFilter,
    bloodTypeFilter,
    donorStatusFilter,
    titerFilter,
    currentBloodbank,
    isDayViewFetching,
    isMonthViewFetching,
    isConfigurationFetching,
    isSidebarFetching,
    appointmentTypes,
    getAppointmentTypes,
    sendErrorLog,
    setDay,
    changeCurrentDate,
  } = props;
  const { t } = useTranslation();

  const defaultAppointmentType = () => ({ value: 'all', label: t('common.all'), filter: 'All' });
  const appointmentTypesTranslation = (appointmentTypes) => [ defaultAppointmentType(), ...appointmentTypes.map(appointmentType => {
    return {
      value: appointmentType.appointmentTypeId,
      label: t(`common.appointmentType.${appointmentType.name}`),
      filter: appointmentType.name,
    }
  }) ];

  const { date } = useParams();

  const donorType = DONOR_TYPE.map(donorType => donorType.value === 'all' ? { ...donorType, label: t('common.all') } : donorType);
  const donorStatus = DONOR_STATUS.map(donorStatus => donorStatus.value === 'all'
    ? { ...donorStatus, label: t(`common.all`) }
    : { ...donorStatus, label: t(`common.donorStatus.${donorStatus.label}`) });
  const titer = BLOOD_TITER.map(titer => titer.value === 'all' ? { ...titer, label: t('common.all') }
    : { ...titer, label: t(`common.titer.${titer.label}`) });

  const [isSideOpen, setSideOpen] = useState(false);
  const [appointmentTypesData, setAppointmentTypesData] = useState([defaultAppointmentType()]);

  i18n.on("languageChanged", () => setAppointmentTypesData(appointmentTypesTranslation(appointmentTypes)));
  useEffect(() => {
    if (!appointmentTypes.length) {
      getAppointmentTypes();
      return;
    }
    setAppointmentTypesData(appointmentTypesTranslation(appointmentTypes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentTypes]);

  const dailyView = location.pathname.includes(routes.DASHBOARD_DAY_ROUTE);
  const prev = usePrevious({currentDay, currentMonth, appTypeFilter, bloodTypeFilter, donorStatusFilter, titerFilter});

  useEffect(() => {
    const startDate = dailyView ? currentDay : currentMonth;
    const disableLoader = !(prev?.currentDay !== currentDay
      || prev?.currentMonth !== currentMonth
      || prev?.appTypeFilter !== appTypeFilter
      || prev?.bloodTypeFilter !== bloodTypeFilter
      || prev?.donorStatusFilter !== donorStatusFilter
      || prev?.titerFilter !== titerFilter);
    try {
      if(currentBloodbank && !isSidebarFetching && !isConfigurationFetching && !isDayViewFetching && !isMonthViewFetching)
      return listenReservations(dailyView, startDate, currentBloodbank,
        {
          next: () => {
            changeFilter(dailyView, startDate, appTypeFilter, bloodTypeFilter, donorStatusFilter, titerFilter, currentBloodbank, disableLoader);
            getDailyReservations(Date.parse(currentDate), appTypeFilter, bloodTypeFilter, donorStatusFilter, titerFilter, currentBloodbank, disableLoader);
            getPrevReservations(Date.parse(currentDate), appTypeFilter, bloodTypeFilter, donorStatusFilter, titerFilter, currentBloodbank, disableLoader);
            getNewUsers(currentBloodbank, disableLoader);
          }
        });
    } catch (err) {
      if (err) sendErrorLog(err.message, err.code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDay, currentMonth, appTypeFilter, bloodTypeFilter, donorStatusFilter, titerFilter, currentBloodbank]);

  useEffect(() => {
    try {
      if(currentBloodbank && !isSidebarFetching)
      return listenNewRegUsers(currentBloodbank, {
        next: () => {
          getNewUsers(currentBloodbank, true);
        }
      });
    } catch (err) {
      if (err) sendErrorLog(err.message, err.code);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBloodbank]);

  useEffect(() => {
    const disableLoader = !(prev?.currentDay !== currentDay
      || prev?.currentMonth !== currentMonth
      || prev?.appTypeFilter !== appTypeFilter
      || prev?.bloodTypeFilter !== bloodTypeFilter
      || prev?.donorStatusFilter !== donorStatusFilter
      || prev?.titerFilter !== titerFilter);

    if (needRefresh && !isSidebarFetching) {
      getDailyReservations(Date.parse(currentDate), appTypeFilter, bloodTypeFilter, donorStatusFilter, titerFilter, currentBloodbank);
      getPrevReservations(Date.parse(currentDate), appTypeFilter, bloodTypeFilter, donorStatusFilter, titerFilter, currentBloodbank);
      getNewUsers(currentBloodbank);
    }

    changeFilter(dailyView, currentDay, appTypeFilter, bloodTypeFilter, donorStatusFilter, titerFilter, currentBloodbank, disableLoader);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needRefresh]);

  const toggleSidebar = () => {
    setSideOpen(!isSideOpen);
  };

  const toggleSidebarClose = () => {
    setSideOpen(false);
  };

  const handleSelectAppointmentType = (data) => {
    setAppTypeFilter(data.filter);
  };

  const handleSelectDonorType = (data) => {
    setBloodTypeFilter(data.value);
  };

  const handleSelectDonorStatus = (data) => {
    setDonorStatusFilter(data.value);
  };

  const handleSelectTiter = (data) => {
    setTiterFilter(data.value);
  };

  return (
    <>
      <div className="page-container">
        <Header />
        <div className="page-content">
          <main className="main">
            <div className="container-fluid content">
              <div className="filters">
                <div className="filters-item">
                  <Sidebar 
                    isOpen={isSideOpen} 
                    toggleSidebar={toggleSidebar} 
                    toggleSidebarClose={toggleSidebarClose}
                  />
                </div>
                <div className="filters-item">
                  <CustomSelect
                    label={t('common.appointmentType.header')}
                    value={appointmentTypesData.find(status => status.filter === appTypeFilter)}
                    data={appointmentTypesData}
                    additionalClassName="xxl"
                    onChange={handleSelectAppointmentType}
                    placeholder={t('common.all')}
                  />
                </div>
                <div className="filters-item">
                  <CustomSelect
                    label={t('common.bloodType')}
                    value={donorType.find(status => status.value === bloodTypeFilter)}
                    data={donorType}
                    additionalClassName="xl"
                    onChange={handleSelectDonorType}
                    placeholder={t('common.all')}
                  />
                </div>
                <div className="filters-item">
                  <CustomSelect
                    label={t('common.donorStatus.header')}
                    value={donorStatus.find(status => status.value === donorStatusFilter)}
                    data={donorStatus}
                    additionalClassName="xl"
                    placeholder={t('common.all')}
                    onChange={handleSelectDonorStatus}
                  />
                </div>
                <div className="filters-item">
                  <CustomSelect
                    label={t('common.titer.title')}
                    value={titer.find(status => status.value === titerFilter)}
                    data={titer}
                    additionalClassName="xl"
                    placeholder={t('common.all')}
                    onChange={handleSelectTiter}
                  />
                </div>
                <div className="filters-item">
                  {/* <Search 
                    placeholder={t('dashboard.search')} 
                    label={t('dashboard.findDonor')} 
                  /> */}
                </div>
              </div>
              { children }
            </div>
          </main>
        </div>
      </div>
      <DonorDetails />
      <DonorSettings />
    </>
  );
};

export default withRouter(Dashboard);
