import React, {useEffect, useState, useContext} from 'react';

import * as dateFns from 'date-fns';
import Modal from '../../common/Modal';
import Button from '../../common/FormFields/Button';
import CustomSelect from '../../common/FormFields/Select';
import Tags from '../../common/Tags';
import CheckboxGroup from '../../common/CheckboxGroup';
import CustomScrollbar from '../../common/CustomScrollbar';
import Datepicker from '../../common/FormFields/Datepicker';

import { WEEKDAYS } from './constants';
import { 
  prepareDatesToMapping,
  prepareHoursToMapping,
  prepareHoursOnDateToMapping,
  prepareToSending,
  changeTimestampByDTS
} from './helpers'
import {
  getStartOfAllDay,
  getStartOfWorkDay,
  workingTimeToTimestamp,
} from '../../../services/helpers';
import { useTranslation } from 'react-i18next';

const ExceptionsSettings = (props) => {
  const {
    isModalOpen, 
    handleClose, 
    availableTimeSlots, 
    currentDate, 
    exceptionRules, 
    exceptionDates,
    exceptionHoursOnDates,
    setExceptionRules,
    setExceptionDates,
    setExceptionHoursOnDates,
    recurrenceRule,
    startDate,
    endDate,
    selectedTimeslot,
  } = props;
  const { t } = useTranslation();

  const [datepickerDate, setDatepickerDate] = useState(currentDate);
  const [allDays, setAllDays] = useState(WEEKDAYS.map(weekday => ({ ...weekday, label: t(`common.day.${weekday.label}`) })));
  const [checkedDays, setCheckedDays] = useState(allDays.filter(weekday => weekday.checked));
  const [timeFrom, setTimeFrom] = useState(availableTimeSlots[0]);
  const [timeTo, setTimeTo] = useState(availableTimeSlots[0]);

  const [datepickerDateHoursDay, setDatepickerDateHoursDay] = useState(currentDate);
  const [timeFromHours, setTimeFromHours] = useState(availableTimeSlots[0]);
  const [timeToHours, setTimeToHours] = useState(availableTimeSlots[0]);

  const [excludeDates, setExcludeDates] = useState(prepareDatesToMapping(exceptionDates));
  const [nonWorkingHours, setNonWorkingHours] = useState(prepareHoursToMapping(exceptionRules));
  const [hoursOnDate, setHoursOnDate] = useState(prepareHoursOnDateToMapping(exceptionHoursOnDates));

  useEffect(() => {
    setAllDays(WEEKDAYS.map(weekday => ({ ...weekday, label: t(`common.day.${weekday.label}`) })));
    setDatepickerDate(currentDate);
    setDatepickerDateHoursDay(currentDate);

    if(startDate.slot) {
      setTimeFrom(availableTimeSlots.find(item => item.value === startDate.slot.value) || availableTimeSlots[0]);
      setTimeTo(availableTimeSlots.find(item => item.value === endDate.slot.value) || availableTimeSlots[0]);
  
      setTimeFromHours(availableTimeSlots.find(item => item.value === startDate.slot.value) || availableTimeSlots[0]);
      setTimeToHours(availableTimeSlots.find(item => item.value === endDate.slot.value) || availableTimeSlots[0]);
    }

    if (exceptionDates) {
      setExcludeDates(() => prepareDatesToMapping(exceptionDates))
    }
    if (exceptionRules) {
      setNonWorkingHours(() => prepareHoursToMapping(exceptionRules))
    }
    if (exceptionHoursOnDates) {
      setHoursOnDate(() => prepareHoursOnDateToMapping(exceptionHoursOnDates))
    }
  }, [exceptionDates, exceptionRules, exceptionHoursOnDates, isModalOpen]);

  useEffect(() => {
    setDatepickerDate(currentDate);
    setDatepickerDateHoursDay(currentDate);
  }, [currentDate]);

  useEffect(() => {
    setCheckedDays(allDays.filter(weekday => weekday.checked));
  }, [allDays]);


  const changeDateFormat = (inputDate) => {
    return {
      id: String(excludeDates.length + 1),
      label: dateFns.format(inputDate, "dd MMMM yyyy"),
      value: dateFns.format(inputDate, "dd_MM_yy"),
      sentValue: getStartOfWorkDay(inputDate)
    }
  };
  const handleExcludeDates = () => {
    if(!(recurrenceRule === null && getStartOfWorkDay(datepickerDate) === getStartOfWorkDay(currentDate))) {

      if (!excludeDates.find((item) => item.value === dateFns.format(datepickerDate, "dd_MM_yy"))) {
        setExcludeDates(() => [...excludeDates, changeDateFormat(datepickerDate)])
      }
    }    
  };


  const changeNonWorkingHoursFormat = (timeFrom, timeTo, checkedDays, inputDate) => {
    const from = workingTimeToTimestamp(timeFrom, getStartOfAllDay(inputDate));
    const to = workingTimeToTimestamp(timeTo, getStartOfAllDay(inputDate));

    const byweekday = checkedDays.map((item) => Number(item.value));
    const labelDays = checkedDays.map((item) => item.label).join(', ');
    const valueDays = checkedDays.map((item) => item.label).join('_');

    return {
      id: String(nonWorkingHours.length + 1),
      label: `From ${timeFrom.label} to ${timeTo.label} on ${labelDays}`,
      value: `${timeFrom.value}_${timeTo.value}_${valueDays}`,
      sentValue: {
        from: changeTimestampByDTS(from, selectedTimeslot?.startDate),
        to: changeTimestampByDTS(to, selectedTimeslot?.startDate),
        byweekday,
      }
    }
  };
  const handleNonWorkingHours = () => {
    const from = workingTimeToTimestamp(timeFrom, getStartOfAllDay(datepickerDate));
    const to = workingTimeToTimestamp(timeTo, getStartOfAllDay(datepickerDate));

    if (checkedDays.length !== 0 && from < to) {
      const valueDays = checkedDays.map((item) => item.label).join('_');
      if (!nonWorkingHours.find((item) => item.value === `${timeFrom.value}_${timeTo.value}_${valueDays}`)) {
        setNonWorkingHours(() => [...nonWorkingHours, changeNonWorkingHoursFormat(timeFrom, timeTo, checkedDays, datepickerDate)])
      }
    }
  };  


  const changeHoursOnDateFormat = (timeFrom, timeTo, date) => {
    const from = workingTimeToTimestamp(timeFrom, date);
    const to = workingTimeToTimestamp(timeTo, date);

    const labelDate = dateFns.format(date, "dd MMMM yyyy");
    const valueDate = dateFns.format(date, "dd_MM_yy");

    return {
      id: String(hoursOnDate.length + 1),
      label: `From ${timeFrom.label} to ${timeTo.label} on ${labelDate}`,
      value: `${timeFrom.value}_${timeTo.value}_${valueDate}`,
      sentValue: {
        from: changeTimestampByDTS(from, selectedTimeslot?.startDate),
        to: changeTimestampByDTS(to, selectedTimeslot?.startDate),
        date,
      }   
    }
  };
  const handleHoursOnDate = () => {
    const from = workingTimeToTimestamp(timeFromHours, getStartOfAllDay(datepickerDateHoursDay));
    const to = workingTimeToTimestamp(timeToHours, getStartOfAllDay(datepickerDateHoursDay));
    const date = getStartOfWorkDay(datepickerDateHoursDay);
  
    if ((from < to) && 
          !(recurrenceRule === null && 
              from < startDate.timestamp && to > endDate.timestamp)) {
      if (!hoursOnDate.find((item) => item.value === `${timeFromHours.value}_${timeToHours.value}_${date}`)) {
        setHoursOnDate(() => [...hoursOnDate, changeHoursOnDateFormat(timeFromHours, timeToHours, date)])
      }
    }
  };

  const deleteExcludeDates = () => {
    setExcludeDates([])
  };

  const deleteNonWorkingHours = () => {
    setNonWorkingHours([])
  };

  const deletehoursOnDate = () => {
    setHoursOnDate([])
  };

  const handleSave = () => {
    setExceptionDates(prepareToSending(excludeDates));
    setExceptionRules(prepareToSending(nonWorkingHours));
    setExceptionHoursOnDates(prepareToSending(hoursOnDate));
    handleClose();
  };

  return (
    <Modal isModalOpen={ isModalOpen } handleModalClose={ handleClose } { ...props }>
      <div className="modal-header">
        <h2 className="modal-title">{t('configuration.exceptions.header')}</h2>
        <button className="modal-close" onClick={ handleClose }>
          <span className="icon-close" />
        </button>
      </div>
      <CustomScrollbar className="modal-body" >
        <section className="modal-content">
          <div className="timeslot-details">
            <div className="timeslot-details-header">
              <h3 className="timeslot-details-title modal-subtitle">{t('configuration.exceptions.excludeDate')}</h3>
              <div className="timeslot-details-controls">
                <div className="timeslot-details-control">
                  <button className="btn-flat" 
                    onClick={handleExcludeDates}
                  >
                    +{t('configuration.exceptions.addException')}
                  </button>
                </div>
              </div>
            </div>
            <div className="form-group inputs-group">
              <div className="inputs-group-item">
                <Datepicker date={datepickerDate} onDateChange={setDatepickerDate} />
              </div>
            </div>
            <div className="form-group">
              <div className="timeslot-details-header">
                <span className="timeslot-details-title">{t('configuration.exceptions.dates')}</span>
                <div className="timeslot-details-controls">
                  <div className="timeslot-details-control">
                    <button className="btn-flat" 
                      onClick={deleteExcludeDates}
                    >
                      {t('configuration.exceptions.clearAll')}
                    </button>
                  </div>
                </div>
              </div>
              <div className="timeslot-details-summary">
                <Tags additionalClass="dual" data={excludeDates} onDeleteTag={setExcludeDates} isOpenable={true} />
              </div>
            </div>
          </div>
        </section>
        <section className="modal-content light">
          <div className="timeslot-details-header">
            <h3 className="timeslot-details-title modal-subtitle">{t('configuration.exceptions.excludeHours')}</h3>
            <div className="timeslot-details-controls">
              <div className="timeslot-details-control">
                <button 
                  className="btn-flat"
                  onClick={handleNonWorkingHours}
                >
                  +{t('configuration.exceptions.addException')}
                </button>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-group inputs-group">
              <div className="inputs-group-item">{t('configuration.exceptions.from')}</div>
              <div className="inputs-group-item">
                <CustomSelect
                  value={ timeFrom }
                  onChange={ setTimeFrom }
                  data={ availableTimeSlots }
                  isSearchable={true}
                  additionalClassName="sm"
                />
              </div>
              <div className="inputs-group-item">{t('configuration.exceptions.to')}</div>
              <div className="inputs-group-item">                
                <CustomSelect
                  value={ timeTo }
                  onChange={ setTimeTo }
                  data={ availableTimeSlots }
                  isSearchable={true}
                  additionalClassName="sm"
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="timeslot-details-header">
              <span className="timeslot-details-title">{t('configuration.exceptions.onThe')}</span>
            </div>
            <div className="timeslot-details-summary">
              <CheckboxGroup data={allDays} onCheck={setAllDays} />
            </div>
          </div>
          <div className="form-group">
            <div className="timeslot-details-header">
              <span className="timeslot-details-title">{t('configuration.exceptions.nonworkingHours')}</span>
              <div className="timeslot-details-controls">
                <div className="timeslot-details-control">
                  <button className="btn-flat" onClick={deleteNonWorkingHours} >{t('configuration.exceptions.clearAll')}</button>
                </div>
              </div>
            </div>
            <div className="timeslot-details-summary">
              <Tags data={nonWorkingHours} onDeleteTag={setNonWorkingHours} isOpenable={true} />
            </div>
          </div>
        </section>
        <section className="modal-content">
          <div className="timeslot-details-header">
            <h3 className="timeslot-details-title modal-subtitle">{t('configuration.exceptions.excludeHoursOnDate')}</h3>
            <div className="timeslot-details-controls">
              <div className="timeslot-details-control">
                <button 
                  className="btn-flat"
                  onClick={handleHoursOnDate}
                >
                  +{t('configuration.exceptions.addException')}
                </button>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-group inputs-group">
              <div className="inputs-group-item">{t('configuration.exceptions.from')}</div>
              <div className="inputs-group-item">
                <CustomSelect
                  value={ timeFromHours }
                  onChange={ setTimeFromHours }
                  data={ availableTimeSlots }
                  isSearchable={true}
                  additionalClassName="sm"
                />
              </div>
              <div className="inputs-group-item">{t('configuration.exceptions.to')}</div>
              <div className="inputs-group-item">                
                <CustomSelect
                  value={ timeToHours }
                  onChange={ setTimeToHours }
                  data={ availableTimeSlots }
                  isSearchable={true}
                  additionalClassName="sm"
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="timeslot-details-header">
              <span className="timeslot-details-title">{t('configuration.exceptions.onThe')}</span>
            </div>
            <div className="inputs-group">
              <div className="inputs-group-item">
                <Datepicker date={datepickerDateHoursDay} onDateChange={setDatepickerDateHoursDay} calendarPosition='top' />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="timeslot-details-header">
              <span className="timeslot-details-title">{t('configuration.exceptions.datesAndHours')}</span>
              <div className="timeslot-details-controls">
                <div className="timeslot-details-control">
                  <button className="btn-flat" onClick={deletehoursOnDate} >{t('configuration.exceptions.clearAll')}</button>
                </div>
              </div>
            </div>
            <div className="timeslot-details-summary">
              <Tags data={hoursOnDate} onDeleteTag={setHoursOnDate} isOpenable={true} />
            </div>
          </div>
        </section>
      </CustomScrollbar>
      <div className="modal-footer light">
        <div className="inputs-group">
          <div className="inputs-group-item">
            <Button type="button" text={t('common.cancel')} onClick={ handleClose } />
          </div>
          <div className="inputs-group-item">
            <Button btnClassName="btn-primary" type="button" text={t('common.save')} onClick={handleSave} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ExceptionsSettings;
